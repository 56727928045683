import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Button, InputForm, InputFormUpload } from "components";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiCloseLine } from "react-icons/ri";
import { fileUpload } from "services/danaProgram/fileService";
import * as Yup from "yup";
import { humanizeFileSize } from "../../../../helpers";
import { isFileSize, isFileTypeMatches } from "../../../../helpers/validation";
import {
  validationCustomErrorMsg,
  validationErrorMsg,
} from "../../../../locale/yup_id";
import { FieldLabel } from "../../../../pages/FDB/Kelompok/FormPendaftaran/NonPerhutananSosial/constants";

const FieldName = {
  TITLE: "Nama Dokumen Lainnya",
  FILE: "Dokumen Lainnya",
  DESC: "Deskripsi",
};

const UploadDocModal = ({
  open,
  onClose,
  onSubmit,
  children,
  className = "",
  allowedFileByExt,
  maxSize,
  setOpenModal,
  isDaprog = false,
  title = "Upload Dokumen Lainnya",
}) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required().label(FieldName.TITLE),
        desc: Yup.string().required().label(FieldName.DESC),
        file: Yup.mixed()
          .test(
            "file",
            ({ label }) =>
              validationErrorMsg.mixed.required.replace("${path}", label),
            (value, context) => {
              let allowedTypes = ["jpg", "png", "jpeg", "pdf", "docx"];
              if (allowedFileByExt) {
                allowedTypes = Array.isArray(allowedFileByExt)
                  ? allowedFileByExt
                  : [allowedFileByExt];
              }

              const maxFileSize = maxSize || 10 * 1024 * 1024;

              // if has value, validate the file
              if (value) {
                // validate file type by extension
                const v1 = isFileTypeMatches({ ext: allowedTypes })(
                  value,
                  context
                );
                if (!v1) {
                  return context.createError({
                    message: validationCustomErrorMsg.isFiletype(
                      allowedTypes.join(", ")
                    )({ label: FieldLabel.FILE }),
                  });
                }

                // validate by file size : max 10MB => 10 * 1024 * 1024 bytes
                const v2 = isFileSize(maxFileSize)(value, context);
                if (!v2) {
                  return context.createError({
                    message: validationCustomErrorMsg.isFileSize(
                      humanizeFileSize(maxFileSize)
                    )({
                      label: FieldLabel.FILE,
                    }),
                  });
                }

                return true;
              }

              // otherwise, return false to render the field required
              return false;
            }
          )
          .required()
          .label(FieldName.FILE),
      }),
    [maxSize, allowedFileByExt]
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (open) {
      methods.reset();
    }
  }, [open, methods]);

  const onFormSubmit = () => {
    return methods.handleSubmit((data) => {
      if (isDaprog) {
        let datas = methods.getValues();
        onSubmit(datas);
      } else {
        onSubmit(data);
      }
      onClose(false);
      methods.resetField("title");
      methods.resetField("file");
      methods.resetField("desc");
    });
  };

  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: Math.round(parseInt(response?.data?.data?.size) / 1024),
      };
      methods.register("fileId");
      methods.setValue("fileId", temp);
      methods.setValue("file", temp, { shouldValidate: true });
    } catch (error) {
      throw error;
    }
  });

  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          <motion.div
            key="modal-file-upload"
            className="bg-white rounded-xl min-w-[400px] max-w-[400px]"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className="flex flex-col p-6">
              <div className="flex-1 flex items-center justify-between">
                <div className={"font-bold text-lg"}>{title}</div>
                <button
                  type={"button"}
                  onClick={() => onClose(false)}
                  className={"text-[#667085]"}
                >
                  <RiCloseLine className={"w-7 h-7"} />
                </button>
              </div>

              <FormProvider {...methods}>
                <form onSubmit={onFormSubmit()} className="space-y-8">
                  <div className={`flex-1 py-4 ${className}`}>
                    <InputForm
                      controllerName={"title"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={FieldName.TITLE}
                      placeholder={FieldName.TITLE}
                      required
                    />

                    <InputFormUpload
                      controllerName={`file`}
                      maxSize={maxSize}
                      label={FieldName.FILE}
                      uploadFile={isDaprog && mutation}
                      required
                      methods={methods}
                    />

                    <InputForm
                      controllerName={"desc"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md`}
                      label={FieldName.DESC}
                      placeholder={FieldName.DESC}
                      textArea
                      rows={4}
                      required
                    />
                  </div>

                  <div className="flex-1 flex items-center space-x-4">
                    <Button
                      type="button"
                      className="text-gray-700 border border-gray-300 bg-white hover:bg-gray-300 flex-1 py-2 rounded"
                      onClick={() => onClose(false)}
                      label="Batal"
                    />
                    <Button
                      type="submit"
                      className="text-white border  border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 py-2 rounded"
                      label="Submit"
                    />
                  </div>
                </form>
              </FormProvider>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default UploadDocModal;
