import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  RiBriefcaseLine,
  RiComputerLine,
  RiEdit2Line,
  RiFileAddLine,
  RiFileTextLine,
  RiMapPinLine,
  RiShieldKeyholeFill,
  RiUploadCloud2Line,
  RiErrorWarningLine,
  RiCheckLine,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { saveCategoryCfP, saveIdCfP, setFormEditable } from "app/actions";
import { createPortal } from "react-dom";

import { Button, ConfirmationModal, HeaderLabel } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import AlertBlankData from "components/molecules/Alert";
import {
  getProposalDetails,
  getStatusCategory,
  postRequirementFile,
} from "services/danaProgram/callForProposalService";

import GeneralInformationIcon from "assets/illustrations/General_Informasi.svg";
import BeneficiariesIcon from "assets/illustrations/Beneficiaries.svg";
import Dokumen_PendukungIcon from "assets/illustrations/Dokumen_Pendukung.svg";
import Lokasi_PelaksanaanIcon from "assets/illustrations/Lokasi_Pelaksanaan.svg";
import Rencana_KerjaIcon from "assets/illustrations/Rencana_Kerja.svg";
import SafeguardsIcon from "assets/illustrations/Safeguards.svg";
import { SuccessToast, ToastContext } from "components/atoms/Toast";
import { useQuery } from "@tanstack/react-query";

const GRID_DATAS = [
  {
    id: "generalInformation",
    title: "General Informasi",
    iconClassName: "border-orangedark-100 bg-orangedark-600",
    icon: <RiComputerLine size={28} className="text-white" />,
    illustration: GeneralInformationIcon,
    menu: "/proposal/call-for-proposal/general-information",
  },
  {
    id: "beneficiaries",
    title: "Beneficiaries",
    iconClassName: "border-[#D1E9FF] bg-[#1570EF]",
    icon: <RiFileTextLine size={28} className="text-white" />,
    illustration: BeneficiariesIcon,
    menu: "/proposal/call-for-proposal/beneficiaries",
  },
  {
    id: "executorLocation",
    title: "Lokasi Pelaksanaan",
    iconClassName: "border-[#D3F8DF] bg-[#099250]",
    icon: <RiMapPinLine size={28} className="text-white" />,
    illustration: Lokasi_PelaksanaanIcon,
    menu: "/proposal/call-for-proposal/location",
  },
  {
    id: "work_plan",
    title: "Rencana Kerja",
    iconClassName: "border-[#FEF7C3] bg-[#CA8504]",
    icon: <RiBriefcaseLine size={28} className="text-white" />,
    illustration: Rencana_KerjaIcon,
    menu: "/proposal/call-for-proposal/workplan",
  },
  {
    id: "safeguards",
    title: "E & S Safeguards",
    iconClassName: "border-[#EBE9FE] bg-[#6938EF]",
    icon: <RiShieldKeyholeFill size={28} className="text-white" />,
    illustration: SafeguardsIcon,
    menu: "/proposal/call-for-proposal/safeguards",
  },
  {
    id: "supportingFiles",
    title: "Dokumen Pendukung",
    iconClassName: "border-[#FCE7F6] bg-[#DD2590]",
    icon: <RiFileAddLine size={28} className="text-white" />,
    illustration: Dokumen_PendukungIcon,
    menu: "/proposal/call-for-proposal/documents",
  },
];

const DetailProposal = ({ type = "new", isComplete = false, onChangeForm }) => {
  const [category, setCategory] = useState([]);
  const [modal, setModal] = useState({ type: "", isOpen: false });
  const navigate = useNavigate();
  const { id: idProposal } = useParams();
  const {
    showToast,
    toastMessage,
    toastDescription,
    showToastMessage,
    hideToastMessage,
  } = useContext(ToastContext);
  const dispatch = useDispatch();
  const { data: detail } = useQuery({
    queryKey: ["cfpDetails", idProposal],
    queryFn: () => getProposalDetails({ cfp_id: idProposal }),
    select: (data) => data?.data?.data,
    onSuccess: (data) => {
      dispatch(setFormEditable(data?.status === "DRAFT"));
    },
  });

  useEffect(() => {
    dispatch(saveIdCfP(idProposal));
  }, []);

  const fetchStatusCategory = async (id) => {
    const response = await getStatusCategory({ id: id });
    const data = await response?.data?.data;

    // adding status response to array category
    const arrayCategory = GRID_DATAS.map((category) => {
      if (!_.isEmpty(Object.keys(data).find((x) => x === category.id))) {
        return {
          ...category,
          data: data[category.id],
        };
      }
    });

    dispatch(saveCategoryCfP(arrayCategory));
    setCategory(arrayCategory);
  };

  const onSubmitFormProposal = async () => {
    const response = await postRequirementFile({ id: idProposal });

    if (response?.data?.code == 200) {
      setModal({ type: "", isOpen: false });
      showToastMessage(
        "Data berhasil dikirim!",
        "Data yang anda masukan sudah berhasil dikirim."
      );
      navigate("/proposal/call-for-proposal");
    }
  };

  useEffect(() => {
    idProposal && fetchStatusCategory(idProposal);
  }, [idProposal]);

  return (
    <Fragment>
      {showToast &&
        createPortal(
          <SuccessToast
            message={toastMessage}
            message_description={toastDescription}
            onClose={hideToastMessage}
          />,
          document.body,
          "toast-success-upload-file-requirement-proposal"
        )}
      {modal.type == "confirm" &&
        createPortal(
          <ConfirmationModal
            open={modal.isOpen}
            onClose={() => setModal({ type: "", isOpen: false })}
            cancelLabel={"Batal"}
            confirmLabel={"Ya"}
            onSubmit={onSubmitFormProposal}
          >
            <div>
              <div className="text-gray-900 font-semibold text-lg">
                Anda yakin akan mengirimkan Proposal ini?
              </div>
              <div className="text-gray-600 text-sm">
                Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan
                pada formulir ini adalah benar adanya. Jika dikemudian hari
                BPDLH menemukan unsur kebohongan dan atau pemalsuan data, kami
                siap dan bersedia untuk diajukan ke ranah hukum sesuai dengan
                undang-undang yang berlaku.
              </div>
            </div>
          </ConfirmationModal>,
          document.body,
          "confirm-upload-file-requirement-proposal-modal"
        )}
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Call For Proposal",
                  path: `/proposal/call-for-proposal`,
                },
                {
                  label: "Tambah Proposal",
                },
              ]}
            />
            <div className="flex mt-5 gap-4">
              <div className="flex-1">
                <HeaderLabel
                  text="Formulir Proposal"
                  description="Formulir Proposal"
                />
              </div>
              <Button
                type="button"
                className="h-11 rounded-lg bg-secondary-orange-600 border border-secondary-orange-600 text-white text-sm px-6 py-2.5 disabled:!text-gray-500 disabled:!bg-gray-300 disabled:!border-gray-300"
                onClick={() => setModal({ type: "confirm", isOpen: true })}
                disabled={detail?.status !== "DRAFT"}
                label={
                  <div className="flex items-center gap-2">
                    <RiUploadCloud2Line />
                    <span className="font-semibold text-sm">
                      Upload Semua Persyaratan
                    </span>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <AlertBlankData
            title={`Lengkapi Dokumen`}
            body={`Anda bisa upload jika semua dokumen kategori di bawah ini sudah dilengkapi.`}
          />
        </div>
        <div className="pt-[46px] grid gap-x-6 gap-y-[50px] grid-cols-3">
          {category.map((x, key) => (
            <div
              key={"category-" + x?.id}
              className="flex flex-col justify-between relative rounded-xl px-6 pt-0 pb-4 border border-gray-200 shadow-[0px 20px 40px 0px rgba(51, 40, 123, 0.04)]"
            >
              <div>
                <div className="flex justify-center w-full -mt-10">
                  <div
                    className={`rounded-[100%] p-3.5 border-[10px] w-fit text-white ${x?.iconClassName}`}
                  >
                    {x?.icon}
                  </div>
                </div>
                <div className="text-gray-900 text-center font-[500] mb-2 mt-3">
                  {x?.title}
                </div>
                <div className="space-y-6">
                  <div className="flex w-full justify-center">
                    <img src={x?.illustration} alt={x?.id} />
                  </div>
                  {x?.data?.status !== "SUBMIT" && (
                    <AlertBlankData
                      title={`Lengkapi Dokumen`}
                      body={`Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan.`}
                    />
                  )}
                </div>
              </div>
              <div>
                {x?.data?.status === "SUBMIT" ? (
                  <div className="mt-6 rounded-2xl gap-1 bg-[#ECFDF3] py-1 pr-3 pl-2.5 flex items-center w-fit">
                    <RiCheckLine size={16} className="text-[#027A48]" />
                    <div className="text-[#027A48] text-sm font-[500]">
                      Data Sudah Dilengkapi
                    </div>
                  </div>
                ) : (
                  <div className="mt-6 rounded-2xl gap-1 bg-[#FFFAEB] py-1 pr-3 pl-2.5 flex items-center w-fit">
                    <RiErrorWarningLine size={16} className="text-[#B54708]" />
                    <div className="text-[#B54708] text-sm font-[500]">
                      Data Belum Lengkap
                    </div>
                  </div>
                )}
                <hr className="mb-4 mt-6" />
                {x?.data?.status === "DRAFT" || x?.data?.status === "SUBMIT" ? (
                  <Button
                    type="button"
                    className="rounded-lg border-gray-300 bg-white text-gray-700 shadow py-2 px-3.5 flex-1 w-full"
                    label={
                      <div className="flex gap-2 justify-center items-center">
                        {detail?.status !== "DRAFT" ? "Lihat Detail" : "Edit"}
                      </div>
                    }
                    onClick={() => {
                      navigate(x?.menu);
                    }}
                  />
                ) : (
                  <Button
                    type="button"
                    className="rounded-lg bg-primary-600 text-white shadow py-2 px-3.5 flex-1 w-full font-semibold"
                    label="Lengkapi"
                    onClick={() => {
                      navigate(x?.menu);
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default DetailProposal;
