import { InputForm, InputFormUploadNew as InputFormUpload } from "components"
import CardForm from "components/molecules/CardForm"
import { useFormContext } from "react-hook-form"

const InformasiPenanggungJawab = ({
  onUploadFile = () => {}
}) => {
  const {
    watch,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useFormContext()
  return (
    <CardForm label={"INFORMASI PENANGGUNG JAWAB"}>
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <InputForm
            controllerName={`pic_information.name`}
            className={`py-2 px-4 mt-2 border w-full rounded-md ${
              errors?.pic_information?.name ? "border-[#F04438]" : "border-green"
            }`}
            label={"Nama Penanggung Jawab"}
            placeholder={"Nama Penanggung Jawab"}
            required={true}
          />
        </div>
        <div className="flex-1 pt-2">
          <InputForm
            controllerName={`pic_information.nik`}
            className={`py-2 px-4 mt-2 border w-full rounded-md ${
              errors?.pic_information?.nik ? "border-[#F04438]" : "border-green"
            }`}
            label={"NIK"}
            placeholder={"NIK"}
            required={true}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <InputForm
            controllerName={`pic_information.phone_number`}
            className={`py-2 px-4 mt-2 border w-full rounded-md ${
              errors?.pic_information?.phone_number ? "border-[#F04438]" : "border-green"
            }`}
            label={"No. Handphone"}
            placeholder={"No. Handphone"}
            required={true}
          />
        </div>
        <div className="flex-1 pt-2">
          <InputFormUpload
            controllerName={`pic_information.pic_nik_document`}
            label={"Dokumen Nik Penanggung Jawab"}
            uploadFile={onUploadFile}
            maxSize={1024 * 1024}
          />
        </div>
      </div>
    </CardForm>
  )
}

export default InformasiPenanggungJawab