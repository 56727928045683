import axios from 'axios';
import { config_daprog, general_config } from 'app/config';
import axiosInstance from 'app/interceptors';

const headers = {
  'Content-Type': 'application/json',
};

export const getListCallforProposal = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/list`,
    {
      params,
      headers,
    }
  );
};

export const getListCallForProposalDeal = () => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/list/deal`,
    {
      headers,
    }
  );
};

export const getStatusCategory = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/get-all-status`,
    {
      params,
      headers,
    }
  );
};

export const postGeneralInformation = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/general-information`,
    payload
  );
};

export const postRequirementFile = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/submit-all-requirement`,
    payload
  );
};

export const getGeneralInformation = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/get-general-information`,
    {
      params,
      headers,
    }
  );
};

export const postWorkPlan = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/work-plan`,
    payload
  );
};

export const postBeneficiaries = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/beneficiaries`,
    payload
  );
};

export const getDetailBeneficiaries = (id) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/get-beneficiaries?id=${id}`
  );
};

export const getDetailProposal = (id) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/${id}/ppd-proposal`
  );
};

export const getDetailWorkPlan = (id) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/${id}/work-plan`
  );
};

export const postLocationExecutions = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/executor-location`,
    payload
  );
};

export const getLocationExecutions = (id) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/${id}/executor-location`
  );
};

export const postSafeguardsStep2 = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/second-step`,
    payload
  );
};

// safeguard form
export const getDetailSafeguards = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/getByProposalId`,
    {
      params,
      headers,
    }
  );
};

export const postBasicInformationSafeGuards = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/first-step`,
    payload
  );
};

export const postSafeguardsStep3 = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/third-step`,
    payload
  );
};

export const postSafeguardsStep4 = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/fourth-step`,
    payload
  );
};

export const postSafeguardsStep5 = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/fifth-step`,
    payload
  );
};

export const postSafeguardsStep6CatA = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/sixth-step/cat-a`,
    payload
  );
};

export const postSafeguardsStep6CatBC = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/sixth-step`,
    payload
  );
};

export const postSafeguardsStep7 = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/safeguard/seven-step`,
    payload
  );
};

// End of Safeguard services

export const getDetailSupportingFile = (id) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/${id}/supporting-file`,
    {
      headers,
    }
  );
};

export const postSupportingFile = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/supporting-file`,
    payload
  );
};

// Call For Proposal - Progress Report

export const getDetailProgressReportByProposal = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/progress-report`,
    { params, headers }
  );
};

export const getDetailOutputProgressReportByProposal = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/progress-report/ouput-achievement`,
    { params, headers }
  );
};

export const updateDetailProgressReportByProposal = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/progress-report/update`,
    payload
  );
};

// Call For Proposal - Activity Report

export const getDetailActivityReport = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/activity-report`,
    { params, headers }
  );
};

export const postResultActivityReport = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/activity-report/activity-result`,
    payload
  );
};

export const postDetailActivityReport = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/activity-report/detail-activity`,
    payload
  );
};

// Call For Proposal - Submit Report

export const postSubmitReport = (payload) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/call-for-proposal/submit-report`,
    payload
  );
};

// Call For Proposal - Activity Category

export const getListActivityCategory = () => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/provider/activity-category`
  );
};

// Call For Proposal - Activity by AWP ID
export const getListActivityByAwpId = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/cms/provider/activity`,
    {
      params: params,
      headers,
    }
  );
};

export const getProposalDetails = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/cms/call-for-proposal/detail`,
    {
      params: params,
      headers,
    }
  );
};
