/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUploadNew as InputFormUpload,
  Label,
} from "components";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import CardForm from "components/molecules/CardForm";
import { RiAddLine, RiDeleteBin6Line, RiDownload2Line } from "react-icons/ri";
import { RiQuestionLine } from "react-icons/ri";
import { RiMap2Line } from "react-icons/ri";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AtomSelect from "components/atoms/Select";
import { useMutation } from "@tanstack/react-query";
import { getProvince } from "services/danaProgram/masterLocation";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import {
  getProvinceService,
  getCityService,
  getDistrictService,
  getWardService,
  getPostCodeService,
} from "services/masterData/region";
import { useState } from "react";
import { useSelector } from "react-redux";
import BranchesInput from "components/atoms/BranchesInput";

const WilayahKerja = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
  regionData,
}) => {
  const [provinces, setProvinces] = useState();
  const [cities, setCities] = useState();
  const [workingAreaCities, setWorkingAreaCities] = useState([]);
  const [valueProvince, setValueProvince] = useState({});
  const [districts, setDistricts] = useState();
  const [wards, setWards] = useState();
  const [zipCodes, setZipCodes] = useState();
  const [activedControllerName, setActivedController] = useState();

  const alamatValidationSchema = yup
    .string()
    .test("isRequired", "alamat wajib diisi", function (value) {
      const { path, createError } = this;

      if (this.parent && this.parent.address === undefined) {
        return true;
      }

      if (value === undefined) {
        return createError({
          path,
          message: "Alamat wajib diisi",
        });
      }

      return true;
    });

  const validationSchema = useMemo(() => {
    return yup.object({
      province_id: yup.object({
        value: yup.string().required("Provinsi wajib diisi"),
      }),

      city_id: yup.object({
        value: yup.string().required("Kota wajib diisi"),
      }),

      district_id: yup.object({
        value: yup.string().required("Kecamatan wajib diisi"),
      }),
      village_id: yup.object({
        value: yup.string().required("Kelurahan wajib diisi"),
      }),
      zip_code: yup.object({
        value: yup.string().required("Kode pos wajib diisi"),
      }),
      address: yup.string().required("Alamat wajib diisi"),
      length_branch: yup.mixed().required("Cabang wajib diisi"),
      area_coverage: yup.array().of(
        yup.object().shape({
          province_id: yup
            .object({
              value: yup
                .string()
                .required("Cakupan wilayah provinsi wajib diisi")
                .label("Cakupan Wilayah Provinsi"),
            })
            .label("Provinsi"),
          city_id: yup
            .object({
              value: yup
                .string()
                .required("Cakupan wilayah kab/kota wajib diisi")
                .label("Kab/Kota"),
            })
            .label("Kab/Kota"),
        })
      ),
      document_branch: yup.mixed().required(),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {},
  });

  const { setValue, getValues, watch } = methods;

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const {
    fields: areaCoverageFields,
    append: appendAreaCoverageFields,
    remove: removeAreaCoverageFields,
  } = useFieldArray({
    control,
    name: "area_coverage",
  });

  const branches = [
    { value: 2, label: "2" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
  ];

  function findBranchByValue(valueToFind) {
    const foundBranch = branches.find((branch) => branch.value === valueToFind);
    return foundBranch;
  }

  const locationMutation = useMutation(async (obj) => {
    if (valueProvince?.name?.includes("area_coverage")) {
      const citiesResponse = await getCityService();
      _mapCity(citiesResponse.data.data, valueProvince?.value);
    }
  });

  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: response.data.data.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: response.data.data.size,
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  const getRegion = useMutation(async (name) => {
    try {
      setProvinces(regionData?.province_ids);
      _mapCity(regionData?.city_ids);
      _mapDistrict(regionData?.district_ids);
      _mapWard(regionData?.village_ids);
      _mapZipCode(regionData?.zip_codes);
    } catch (error) {
      throw error;
    }
  });

  const _mapProvince = (data) => {
    const transformedData = data.map((item, index) => ({
      label: item.namaProvinsi,
      value: item.id,
      country: item.namaNegara,
    }));
    setProvinces(transformedData);
  };

  const _mapCity = (data) => {
    let provinceId = watch("province_id");
    const transformedData = data;
    if (provinceId) {
      const filteredData = data.filter(
        (item) => item.province === provinceId?.label
      );
      setCities(filteredData);
    } else {
      setCities(transformedData);
    }
  };

  const _mapDistrict = (data) => {
    let cityId = watch("city_id");
    const transformedData = data;
    if (cityId) {
      const filteredData = transformedData.filter(
        (item) => item.city === cityId?.label
      );
      setDistricts(filteredData);
    } else {
      setDistricts(transformedData);
    }
  };

  const _mapWard = (data) => {
    let districtId = watch("district_id");
    const transformedData = data;
    if (districtId) {
      const filteredData = transformedData.filter(
        (item) => item.district === districtId?.label
      );
      setWards(filteredData);
    } else {
      setWards(transformedData);
    }
  };

  const _mapZipCode = (data) => {
    let villageId = watch("village_id");
    const transformedData = data;
    if (villageId) {
      const filteredData = transformedData.filter(
        (item) => item.ward === villageId?.label
      );
      setZipCodes(filteredData);
    } else {
      setZipCodes(transformedData);
    }
  };

  // Function to filter out areaCoverageFields based on temp
  function filterAreaCoverageFields(temp) {
    for (let i = areaCoverageFields.length - 1; i >= 0; i--) {
      const { city_id, province_id } = areaCoverageFields[i];

      // Check if current entry does not match any entry in temp
      const isMatch = temp.some(
        (tempEntry) =>
          city_id?.value === tempEntry.city_id &&
          province_id?.value === tempEntry.province_id
      );

      if (!isMatch) {
        // Remove entry if no match
        removeAreaCoverageFields(i);
      }
    }
  }

  // Function to append area coverage based on draft data
  function appendAreaCoverage(draftData) {
    if (!draftData?.length) {
      if (
        draftData?.area_coverage?.length < 1 &&
        areaCoverageFields.length === 0
      ) {
        appendAreaCoverageFields({
          province_id: "",
          city_id: "",
        });
      }
      return;
    }

    const temp = draftData.map(({ province_id, city_id }) => ({
      province_id,
      city_id,
    }));

    draftData.forEach((data) => {
      const province = regionData?.province_ids?.find(
        (item) => item.value === data.province_id
      );
      const city = regionData?.city_ids?.find(
        (item) => item.value === data.city_id
      );

      if (
        areaCoverageFields.every(
          (field) => field.city_id === city || field.province_id === province
        )
      ) {
        appendAreaCoverageFields({ province_id: province, city_id: city });
      }
    });

    filterAreaCoverageFields(temp);
  }

  useEffect(() => {
    if (!draftData) return;

    Object.entries(draftData).forEach(([key, value]) => {
      switch (key) {
        case "province_id":
        case "city_id":
        case "district_id":
        case "village_id":
        case "zip_code":
          if (typeof value !== "object") {
            setValue(
              key,
              regionData[key + "s"]?.find((item) => item.value === value)
            );
          } else {
            setValue(key, value);
          }
          break;

        case "area_coverage":
          appendAreaCoverage(value);
          break;

        case "document_branch":
          if (value) {
            getFileMutation.mutate({ id: value, key });
          }
          break;

        case "length_branch":
          setValue(key, value);
          break;

        default:
          setValue(key, value);
          break;
      }
    });

    // Trigger validation on form fields
    methods.trigger();
  }, [draftData, regionData, setValue]);

  useEffect(() => {
    locationMutation.mutate();
    getRegion.mutate();
  }, [regionData]);

  useEffect(() => {
    getRegion.mutate();
  }, [
    methods.watch("province_id"),
    methods.watch("city_id"),
    methods.watch("district_id"),
    methods.watch("village_id"),
  ]);

  return (
    <FormProvider {...methods}>
      <>
        <CardForm label={"Cakupan Wilayah Kerja"}>
          <div className="flex flex-col rounded-xl border bg-white">
            <div className="flex gap-16 bg-[#EEFFF4] p-4">
              <div className="w-16">no</div>
              <div className="flex-1">Provinsi</div>
              <div className="flex-1">Kab/Kota</div>
              <div className="w-16"></div>
            </div>
            {areaCoverageFields?.map((data, index) => (
              <div
                className="flex gap-16 items-center px-4 border-b py-2"
                key={data.id}
              >
                <div className="w-16">{index + 1}</div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`area_coverage[${index}].province_id`}
                    options={provinces}
                    className="basic-single mt-2"
                    placeholder={"Pilih Provinsi"}
                    activedControllerName={setActivedController}
                  />
                </div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`area_coverage[${index}].city_id`}
                    options={cities}
                    className="basic-single mt-2"
                    placeholder={"Pilih Kab/Kota"}
                  />
                </div>
                <div className="w-16">
                  {index !== 0 && (
                    <div className="flex p-3 justify-center text-white rounded-lg">
                      <Button
                        className="p-4 bg-[#D92D20] border rounded-lg"
                        label={
                          <div className="flex items-center gap-2 font-[14px]">
                            <RiDeleteBin6Line />
                          </div>
                        }
                        onClick={() => {
                          removeAreaCoverageFields(index);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className="flex flex-row-reverse my-5 mx-4 ">
              <Button
                className="p-4 border rounded-lg"
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Cakupan wilayah kerja
                  </div>
                }
                onClick={() =>
                  appendAreaCoverageFields({
                    province_id: null,
                    city_id: null,
                  })
                }
              />
            </div>
          </div>
        </CardForm>

        <CardForm label={"Lokasi Kantor"}>
          <>
            <div className="flex gap-2 border rounded-lg p-4 items-center bg-white">
              <RiQuestionLine />
              <Label text="Dimana Lokasi Kantor Pusat Anda Saat Ini?" />
            </div>
            <div className="flex gap-4">
              <AtomSelect
                controllerName={`province_id`}
                options={provinces}
                className="basic-single mt-2"
                label={"Pronvisi"}
                required={true}
              />
              <AtomSelect
                controllerName={`city_id`}
                options={methods.watch("province_id") ? cities : []}
                className="basic-single mt-2"
                label={"Kota/Kabupaten"}
                required={true}
              />
            </div>
            <div className="flex gap-4">
              <AtomSelect
                controllerName={`district_id`}
                options={methods.watch("city_id") ? districts : []}
                className="basic-single mt-2"
                label={"Kecamatan"}
                required={true}
                Ob
              />
              <AtomSelect
                controllerName={`village_id`}
                options={methods.watch("district_id") ? wards : []}
                className="basic-single mt-2"
                label={"Kelurahan"}
                required={true}
              />
            </div>
            <div className="flex gap-4">
              <AtomSelect
                controllerName={`zip_code`}
                options={methods.watch("village_id") ? zipCodes : []}
                className="basic-single mt-2"
                label={"Kode Pos"}
                required={true}
              />
              <InputForm
                controllerName={`address`}
                className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                label={"Alamat"}
                required={true}
                placeholder={"alamat"}
                textArea={true}
              />
            </div>
            <div className="hidden flex-col">
              <label className="label font-semibold text-[14px]">
                <span className={`label-text`}>Titik Lokasi Alamat</span>
              </label>
              <div className="w-full mt-2 flex justify-center gap-2 items-center bg-white p-4 border rounded-lg">
                <RiMap2Line />
                Pilih lewat peta
              </div>
            </div>
            <div className="border-b" />
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg">
                <RiQuestionLine />
                Berapa Cabang yang dimiliki?
              </div>
            </div>
            <BranchesInput
              controllerName={"length_branch"}
              style={{ width: "calc(100vw/10)" }}
              label="Pilih Jumlah Cabang"
              required={true}
            />
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                <RiQuestionLine />
                Silahkan upload data cabang yang Lembaga/ Instansi/ Perusahaan
                Anda miliki?
              </div>
            </div>
            <div className="hidden gap-4">
              <div className="flex w-full mt-2 gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                <RiQuestionLine />
                Silahkan download template yang sudan disediakan.
              </div>
              <div className="flex mt-2 w-full">
                <button className="bg-white p-4 border rounded-xl">
                  <div className="flex items-center gap-2">
                    <RiDownload2Line />
                    Download Template
                  </div>
                </button>
              </div>
            </div>
            <div className="py-2" />
            <InputFormUpload
              controllerName={`document_branch`}
              required={true}
              label={"Upload Data Cabang"}
              uploadFile={mutation}
              methods={methods}
            />
          </>
        </CardForm>

        <BottomFormAction
          lastStep={true}
          backButtonProps={{
            type: "button",
          }}
          draftButtonProps={{
            type: "button",
          }}
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={false}
          submitActionButton={() => {
            console.log("Errors :", errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </>
    </FormProvider>
  );
};

export default WilayahKerja;
