import React, { useMemo, useEffect } from "react";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUploadNew as InputFormUpload,
} from "components";
import { FormProvider, useForm } from "react-hook-form";
import CardForm from "components/molecules/CardForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";

const TatakelolaKelembagaan = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const validationSchema = useMemo(() => {
    return yup.object({
      institutional_transparency: yup
        .mixed()
        .required()
        .nonNullable()
        .label("tingkat penerapan akuntabilitas dan transparasi wajib diisi"),
      institutional_transparency_document: yup
        .mixed()
        .required(
          "file tingkat penerapan akuntabilitas dan transparasi wajib diisi"
        ),
      institutional_transparency_desc: yup
        .string()
        .required(
          "deskripsi dokumen tingkat penerapan akuntabilitas dan transparasi wajib diisi"
        ),
      anti_corruption: yup
        .mixed()
        .required()
        .nonNullable()
        .label("tingkat Pengelolaan dan penerapan anti korupsi wajib diisi"),
      anti_corruption_document: yup
        .mixed()
        .required(
          "file tingkat Pengelolaan dan penerapan anti korupsi wajib diisi"
        ),
      anti_corruption_desc: yup
        .string()
        .required(
          "deskripsi dokumen tingkat Pengelolaan dan penerapan anti korupsi wajib diisi"
        ),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { setValue } = methods;

  useEffect(() => {
    if (draftData && Object.keys(draftData).length > 0) {
      Object.keys(draftData).forEach((key) => {
        if (key.includes("document")) {
          getFileMutation.mutate({
            id: draftData[key],
            key: key,
          });
        } else {
          setValue(key, draftData[key]);
        }
      });
      methods.trigger();
    }
  }, [draftData, setValue, methods]);

  const {
    formState: { errors, isValid },
    control,
  } = methods;
  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: response.data.data.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={"Tata Kelola Kelembagaan"}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Bagaimana tingkat penerapan akuntabilitas dan transparasi di lembaga/institusi/perusahaan anda?"
                  }
                  values={[
                    {
                      value: 1,
                      label: "1",
                    },
                    {
                      value: 2,
                      label: "2",
                    },
                    {
                      value: 3,
                      label: "3",
                    },
                    {
                      value: 4,
                      label: "4",
                    },
                    {
                      value: 5,
                      label: "5",
                    },
                  ]}
                  controllerName={"institutional_transparency"}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  methods={methods}
                  uploadFile={mutation}
                  controllerName={`institutional_transparency_document`}
                  label={
                    "Dokumen yang menunjukan kebenaran nilai Skala yang dipilih (*Upload Mulltiple) "
                  }
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`institutional_transparency_desc`}
                  className={`px-4 mt-8 border w-full rounded-md h-32 items-start`}
                  label={"Uraian singkat terkait isi dokumen"}
                  placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                  textArea={true}
                  required={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Sejauh mana tingkat Pengelolaan dan penerapan anti korupsi di lembaga Anda? (dari skala 1-5)"
                  }
                  controllerName={"anti_corruption"}
                  values={[
                    {
                      value: 1,
                      label: "1",
                    },
                    {
                      value: 2,
                      label: "2",
                    },
                    {
                      value: 3,
                      label: "3",
                    },
                    {
                      value: 4,
                      label: "4",
                    },
                    {
                      value: 5,
                      label: "5",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  uploadFile={mutation}
                  controllerName={`anti_corruption_document`}
                  label={
                    "Dokumen yang menunjukan kebenaran nilai Skala yang dipilih (*Upload Mulltiple)  "
                  }
                  methods={methods}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`anti_corruption_desc`}
                  className={`px-4 mt-8 border w-full rounded-md h-32 items-start`}
                  label={"Uraian singkat terkait isi dokumen"}
                  placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                  textArea={true}
                  required={true}
                />
              </div>
            </div>
          </>
        </CardForm>

        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default TatakelolaKelembagaan;
