import React, { useMemo, useEffect, useState } from "react";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUploadNew as InputFormUpload,
} from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiQuestionLine } from "react-icons/ri";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { validationErrorMsg } from "locale/yup_id";
import { isCitizenIdValid } from "helpers/validation";
import { useSelector } from "react-redux";
import { getCollaborationFields } from "services/danaProgram/masterDataService";
import { _mapMasterData } from "helpers/mapMasterData";

const Subgrant = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const masterData = useSelector((state) => state?.draftMasterData?.data || {});
  const [arrayLength, setArrayLength] = useState(draftData?.partners?.length);
  const validationSchema = useMemo(() => {
    return yup.object({
      partners_total: yup
        .number()
        .required("Total lembaga yang bekerja sama harus diisi")
        .min(
          0,
          "Total lembaga yang bekerja sama harus lebih besar dari atau sama dengan 0"
        ),
      partners: yup.array().of(
        yup.object().shape({
          partner_name: yup
            .string()
            .when("partners_total", {
              is: (val) => val > 0,
              then: (s) => s.required("Nama Rekanan Mitra wajib diisi"),
              otherwise: (s) => s,
            })
            .label("Nama Rekanan Mitra"),
          partner_field: yup
            .mixed()
            .when("partners_total", {
              is: (val) => val > 0,
              then: (s) => s.required("Bidang kerjasama wajib diisi"),
              otherwise: (s) => s,
            })
            .label("Bidang kerja sama"),
          partner_document: yup
            .mixed()
            .when("partners_total", {
              is: (val) => val > 0,
              then: (s) => s.required("Dokumen kerja sama wajib diisi"),
              otherwise: (s) => s,
            })
            .label("Dokumen kerja sama"),
        })
      ),
    });
  }, []);

  let partnersOptions = [
    {
      label: "Belum Ada",
      value: 0,
    },
    {
      label: "1 Lembaga",
      value: 1,
    },
    {
      label: "2 Lembaga",
      value: 2,
    },
    {
      label: "3 Lembaga",
      value: 3,
    },
    {
      label: "4 Lembaga",
      value: 4,
    },
    {
      label: "5 Lembaga",
      value: 5,
    },
  ];

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const {
    formState: { errors, isValid },
    control,
    watch,
    setValue,
    getValues,
  } = methods;

  const [cooperations, setCooperations] = useState([]);

  const getCollaboration = useMutation(async () => {
    try {
      const collabResponse = await getCollaborationFields();
      setCooperations(_mapMasterData(collabResponse?.data?.data));
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);

        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: response.data.data.size,
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: response.data.data.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const [numberOfInstitutions, setNumberOfInstitutions] = useState(0);

  useEffect(() => {
    getCollaboration.mutate();
  }, []);

  const partnersTotal = watch("partners_total");
  useEffect(() => {
    setNumberOfInstitutions(partnersTotal);
  }, [partnersTotal]);

  useEffect(() => {
    for (let key in draftData) {
      if (key.includes("total")) {
        setValue(key, draftData[key]);
      } else {
        draftData[key]?.map((data, index) => {
          for (let e in data) {
            if (e === "partner_document") {
              getFileMutation.mutate({
                id: data[e],
                key: `${key}.${index}.${e}`,
              });
            } else {
              setValue(`${key}.${index}.${e}`, draftData[key][index][e]);
            }
          }
        });
      }
    }
    let methods = getValues();
    console.log(methods);
  }, [draftData]);

  const cardForms = Array.from({ length: numberOfInstitutions }).map(
    (_, index) => (
      <CardForm
        key={index}
        label={`Lembaga ${index + 1}`}
        buttonCollapse={true}
        childerForm={true}
      >
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputForm
              controllerName={`partners.${index}.partner_name`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${
                errors.partner_name ? "border-[#F04438]" : "border-green"
              }`}
              label={"Nama Rekanan Mitra"}
              placeholder={"Nama rekanan mitra"}
              required={true}
            />
          </div>
          <div className="flex-1 pt-2">
            <AtomSelect
              controllerName={`partners.${index}.partner_field`}
              options={cooperations ? cooperations : []}
              className="basic-single mt-2"
              label="Bidang kerjasama"
              required={true}
              isMulti
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1 pt-2">
            <InputFormUpload
              uploadFile={mutation}
              controllerName={`partners.${index}.partner_document`}
              label={"Dokumen kerjasama"}
              methods={methods}
            />
          </div>
          <div className="flex-1 pt-2"></div>
        </div>
      </CardForm>
    )
  );

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={"Subgrant"}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Berapa banyak lembaga/ perusahaan yang bekerja sama dengan lembaga/ institusi/ perusahaan Anda, terutama di daerah tempat pendistribusian dana program?"
                  }
                  controllerName={"partners_total"}
                  values={partnersOptions}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center bg-white p-4 border rounded-lg text-[#026AA2] border-[#7CD4FD]">
                <RiQuestionLine />
                Silahkan lengkapi data Lembaga/ perusahaan sub grant Anda
              </div>
            </div>
            <>{cardForms}</>
          </>
        </CardForm>

        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
            console.log("back button :", values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default Subgrant;
