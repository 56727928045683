import {
  InputForm,
  InputFormRadio,
  InputFormUploadNew as InputFormUpload,
} from "components"
import AtomDatePicker from "components/atoms/Datepicker"
import { useFormContext } from "react-hook-form"

const { default: CardForm } = require("components/molecules/CardForm")

const InformasiUmum = ({
  optJenisBeneficiaries = [],
  onUploadFile = () => {}
}) => {
  const {
    watch,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useFormContext()
  return(
    <CardForm label={"INFORMASI UMUM"}>
      <div className="flex pt-2">
        <InputFormRadio 
          label={"Pilih Jenis Beneficiaries"}
          controllerName={"general_information.beneficiaries_type"}
          values={optJenisBeneficiaries}
        />
      </div>
      {watch('general_information.beneficiaries_type') === 'Lainnya' && (
        <div className="flex pt-2">
          <InputForm
            controllerName={`general_information.other_beneficiaries_type`}
            className={`py-2 px-4 mt-2 border w-full rounded-md ${
              errors.lainnya ? "border-[#F04438]" : "border-green"
            }`}
            label={"Jenis Beneficiaries Lainnya"}
            placeholder={"Jenis Beneficiaries Lainnya"}
            textArea={true}
            required={false}
          />
        </div>
      )}

      {watch('general_information.isPersonal') === true ? (
        <>
          <div className="flex pt-2">
            <InputForm
              controllerName={`general_information.fullname`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${
                errors?.general_information?.fullname ? "border-[#F04438]" : "border-green"
              }`}
              label={"Nama Lengkap"}
              placeholder={"Nama Lengkap"}
              required={true}
            />
          </div>
          <div className="flex gap-4">
            <div className="flex-1 pt-2">
              <InputForm
                controllerName={`general_information.nik`}
                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                  errors?.general_information?.nik ? "border-[#F04438]" : "border-green"
                }`}
                label={"NIK"}
                placeholder={"NIK"}
                required={true}
              />
            </div>
            <div className="flex-1 pt-2">
              <InputFormRadio 
                label={"Jenis Kelamin"}
                controllerName={"general_information.gender"}
                values={[{
                  label: "Laki-laki",
                  value: "Laki-laki"
                }, {
                  label: "Perempuan",
                  value: "Perempuan"
                }]}
                required={true}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex-1 pt-2">
              <AtomDatePicker
                newDate={true}
                controllerName={`general_information.birthdate`}
                label="Tanggal Lahir/ Tanggal Berdiri"
                required={true}
                customPicker={true}
              />
            </div>
            <div className="flex-1 pt-2">
              <InputForm
                controllerName={`general_information.phone_number`}
                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                  errors?.general_information?.no_handphone ? "border-[#F04438]" : "border-green"
                }`}
                label={"Nomor Handphone"}
                placeholder={"Nomor Handphone"}
                required={true}
              />
            </div>
          </div>
          <div className="flex pt-2">
            <InputFormUpload
              controllerName={`general_information.nik_document`}
              label={"Dokumen NIK"}
              uploadFile={onUploadFile}
              maxSize={1024 * 1024}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex pt-2">
            <InputForm
              controllerName={`general_information.institution_name`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${
                errors?.general_information?.institution_name ? "border-[#F04438]" : "border-green"
              }`}
              label={"Nama Badan Usaha/ Lembaga/ Kelompok/ Masyarrakat Hukum Adat/ Lainnya"}
              placeholder={"-"}
              required={true}
            />
          </div>
          <div className="flex gap-4">
            <div className="flex-1 pt-2">
              <InputForm
                controllerName={`general_information.deed_number`}
                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                  errors?.general_information?.deed_number ? "border-[#F04438]" : "border-green"
                }`}
                label={"No. Akta/ No.Izin/ No. SK Penetapan"}
                placeholder={"-"}
                required={true}
              />
            </div>
            <div className="flex-1 pt-2">
              <InputForm
                controllerName={`general_information.nib_number`}
                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                  errors?.general_information?.nib_number ? "border-[#F04438]" : "border-green"
                }`}
                label={"NIB (Khusus Untuk Badan Usaha Dan Lembaga)"}
                placeholder={"-"}
                required={true}
              />
            </div>
          </div>
          <div className="w-6/12 pt-2">
            <InputForm
              controllerName={`general_information.npwp`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${
                errors?.general_information?.npwp ? "border-[#F04438]" : "border-green"
              }`}
              label={"NPWP (Khusus Untuk Badan Usaha Dan Lembaga)"}
              placeholder={"-"}
              required={true}
            />
          </div>
          <div className="flex gap-4">
            <div className="flex-1 pt-2">
              <AtomDatePicker
                newDate={true}
                controllerName={`general_information.est_date`}
                label="Tanggal Lahir/ Tanggal Berdiri"
                required={false}
                customPicker={true}
              />
            </div>
            <div className="flex-1 pt-2">
              <InputForm
                controllerName={`general_information.office_phone_number`}
                className={`py-2 px-4 mt-2 border w-full rounded-md ${
                  errors?.general_information?.office_phone_number ? "border-[#F04438]" : "border-green"
                }`}
                label={"Nomor Telp Kantor"}
                placeholder={"Nomor Telp Kantor"}
                required={true}
              />
            </div>
          </div>
          <div className="flex pt-2">
            <InputForm
              controllerName={`general_information.institution_description`}
              className={`py-2 px-4 mt-2 border w-full rounded-md ${
                errors?.general_information?.institution_description ? "border-[#F04438]" : "border-green"
              }`}
              label={"Jelaskan Secara Singkat Tentang Badan Usaha/ Lembaga/ Kelompok Masyarakat/ Usaha Lainnya Yang Anda Kelola"}
              placeholder={"-"}
              textArea={true}
              required={true}
            />
          </div>
          <div className="flex pt-2">
            <InputFormUpload
              controllerName={`general_information.deed_document`}
              label={"Dokumen Akta/ Izin/ SK"}
              uploadFile={onUploadFile}
              maxSize={1024 * 1024}
            />
          </div>
          <div className="flex pt-2">
            <InputFormUpload
              controllerName={`general_information.support_document`}
              label={"Dokumen NIB/ NPWP/ Pendukung Lainnya"}
              uploadFile={onUploadFile}
              maxSize={1024 * 1024}
            />
          </div>
        </>
      )}
      
    </CardForm>
  )
}

export default InformasiUmum