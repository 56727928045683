import { config_daprog } from "app/config";
import axiosInstance from "app/interceptors";

export const getDirectSubmissionList = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/direct-submission/list`,
    {
      params,
    }
  );
};

export const getDirectSubmissionDetail = (id) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/cms/direct-submission/detail`,
    {
      params: {
        id,
      },
    }
  );
};

export const postDirectSubmissionStep1 = (body) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/direct-submission/first-step`,
    body
  );
};

export const postDirectSubmissionStep2 = (body) => {
  return axiosInstance.post(
    `${config_daprog.BASE_URL}/v1/direct-submission/second-step`,
    body
  );
};

export const reviewDirectSubmission = (body) => {
  return axiosInstance.put(
    `${config_daprog.BASE_URL}/v1/cms/direct-submission/change-status`,
    body
  );
};

export const deleteDirectSubmission = (id) => {
  return axiosInstance.delete(
    `${config_daprog.BASE_URL}/v1/cms/direct-submission`,
    {
      data: {
        id,
      },
    }
  );
};

export const getDirectSubmissionSectorList = (params) => {
  return axiosInstance.get(
    `${config_daprog.BASE_URL}/v1/direct-submission/list-sector`,
    {
      params,
    }
  );
};
