import React, { useEffect, useMemo, useRef } from "react";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
  InputFormUploadNew as InputFormUpload,
} from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import CardForm from "components/molecules/CardForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiAddLine, RiDeleteBin6Line, RiQuestionLine } from "react-icons/ri";
import AtomDatePicker from "components/atoms/Datepicker";
import validationSchema from "./validationSchema";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { map } from "lodash";
import FileInfoField from "components/atoms/FileInfoField";
import { humanizeFileSize } from "helpers";

const ProcurmentPage = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [expand, setExpand] = useState(true);

  const [controllerTarget, setControllerTarget] = useState();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      structure_member: [
        {
          position: "",
          total_member: "",
        },
        { position: "", total_member: "" },
      ],
      procurement_experience: draftData?.institutional_experience
        ?.procurement_experience?.length === 0 && [
        {
          job_name: "",
          location: "",
          employer_name: "",
          employer_address: "",
          no_contract: "",
          date_contract: new Date(),
          value_contract: "",
          end_date_contract: new Date(),
          end_date_bast: new Date(),
        },
      ],

      document_support: draftData?.document_support ?? [
        {
          url_file_cooperative_contract: "",
          description_cooperative_contract: "",
          url_file_completion_report: "",
          description_completion_report: "",
        },
      ],
    },
  });

  function handleExpand() {
    setExpand(!expand);
  }

  const {
    formState: { errors, isValid },
    control,
    setValue,
    register,
  } = methods;

  useEffect(() => {
    appendProcurementExperience({
      job_name: "",
      location: "",
      employer_name: "",
      employer_address: "",
      no_contract: "",
      date_contract: "",
      value_contract: "",
      end_date_contract: "",
      end_date_bast: "",
    });
  }, []);

  useEffect(() => {
    setValue(controllerTarget, (prevUrls) => {
      const newUrls = [...new Set([...prevUrls, ...uploadedUrls])];
      return newUrls;
    });
  }, [uploadedUrls]);

  useEffect(() => {
    const updateProcurementExperience = async (key) => {
      if (
        key === "procurement_experience" &&
        draftData?.institutional_experience?.[key]
      ) {
        if (Array.isArray(draftData?.institutional_experience?.[key])) {
          const procurementExperience =
            draftData?.institutional_experience?.[key];

          const updatedProcurement =
            procurementExperience.length > 0 &&
            procurementExperience.map((item) => ({
              job_name: item.job_name || "",
              location: item.location || "",
              employer_name: item.employer_name || "",
              employer_address: item.employer_address || "",
              no_contract: item.no_contract || "",
              date_contract: item.date_contract || new Date(),
              value_contract: item.value_contract || "",
              end_date_contract: item.end_date_contract || new Date(),
              end_date_bast: item.end_date_bast || new Date(),
            }));

          setValue("procurement_experience", updatedProcurement);
        }
      }
    };

    const updateValues = async () => {
      if (draftData !== null) {
        if (draftData?.institutional_experience) {
          const keys = Object.keys(draftData?.institutional_experience);
          for (const key of keys) {
            if (key === "procurement_experience") {
              await updateProcurementExperience(key);
            } else if (key === "structure_member") {
              if (Array.isArray(draftData?.institutional_experience?.[key])) {
                const structureMember =
                  draftData?.institutional_experience?.[key];

                const updatedMember =
                  structureMember.length > 0
                    ? structureMember.map((item) => ({
                        position: item.position || "",
                        total_member: item.total_member || "",
                      }))
                    : [
                        {
                          position: "",
                          total_member: "",
                        },
                      ];
                setValue("structure_member", updatedMember);
              }
            } else if (
              key.includes("url") &&
              key !== "url_file_support_document"
            ) {
              getFileMutation.mutate({
                id: draftData?.institutional_experience?.[key],
                key: `${key}`,
              });
            } else if (key === "url_file_support_document") {
              setValue(
                "url_file_support_document",
                draftData?.institutional_experience?.[key]
              );
            } else {
              await setValue(key, draftData?.institutional_experience?.[key]);
            }
          }
        }
        if (draftData?.document_support) {
          Object.keys(draftData?.document_support).forEach((obj) => {
            if (obj.includes("url")) {
              getFileMutation.mutate({
                id: draftData?.document_support?.[obj],
                key: `${obj}`,
              });
            } else {
              setValue(obj, draftData?.document_support?.[obj]);
            }
          });
        }
        methods.trigger();
      } else {
        for (let i = 0; i < 2; i++) {
          appendStructureMember({
            position: "",
            total_member: "",
          });
        }
      }
    };

    if (draftData) {
      updateValues();
    }
  }, [draftData, setValue, methods]);

  const {
    fields: structureMemberFields,
    append: appendStructureMember,
    remove: removeStructureMember,
  } = useFieldArray({
    control,
    name: "structure_member",
  });

  const {
    fields: procurementExperienceFields,
    append: appendProcurementExperience,
    remove: removeProcurementExperience,
  } = useFieldArray({
    control,
    name: "procurement_experience",
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `url_file_support_document`,
  });

  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);

      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: response.data.data.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: response.data.data.size,
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
    let modifValue = "";
    let count = 1;
    if (oriValue !== "" && oriValue.length > 3) {
      const splitValue = oriValue.split("");
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  return (
    <>
      <FormProvider {...methods}>
        <CardForm label={"Data Pengalaman Lembaga"} buttonCollapse={true}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Apakah lembaga/ institusi/ perusahaan Anda menjalankan mekanisme procurement dalam memenuhi kebutuhan pengadaan lembaga/ institusi/ perusahaan Anda?"
                  }
                  controllerName={"is_running_mechanism_procurement"}
                  values={[
                    {
                      label: "ya",
                      value: true,
                    },
                    {
                      label: "tidak",
                      value: false,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Apakah Anda memiliki aturan & ketentuan (SOP) yg mengatur proses pengadaan di lembaga/ instansi/ perusahaan Anda?"
                  }
                  controllerName={"is_any_sop"}
                  values={[
                    {
                      label: "ya",
                      value: true,
                    },
                    {
                      label: "tidak",
                      value: false,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  controllerName={`url_file_sop`}
                  label={"Dokumen SOP"}
                  uploadFile={mutation}
                  methods={methods}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`description_sop`}
                  className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                  label={"Uraian Singkat Terkait Isi Dokumen"}
                  placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                  textArea={true}
                  required={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Bagaimana cara Anda menyelenggarakan kegiatan pengadaan yang dibutuhkan lembaga Anda?"
                  }
                  controllerName={"formulate_strategic_plan"}
                  values={[
                    {
                      label: "Penunjukan Langsung",
                      value: "Penunjukan Langsung",
                    },
                    {
                      label: "Seleksi/Lelang Terbuka",
                      value: "Seleksi/Lelang Terbuka",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Berapa nilai (nominal) minimum dari pengadaan yang biasa lembaga/ institusi/ perusahaan Anda lakukan melalui metode penujukan langsung?"
                  }
                  controllerName="minimum_value_procurement"
                  isCustomOption={true}
                  customOption={
                    draftData?.institutional_experience
                      ?.custom_option_minimum_value_procurement
                  }
                  values={[
                    {
                      label: "100 Juta",
                      value: "100 Juta",
                    },
                    {
                      label: "250 Juta",
                      value: "250 Juta",
                    },
                    {
                      label: "350 Juta",
                      value: "350 Juta",
                    },
                    {
                      label: "500 Juta",
                      value: "500 Juta",
                    },
                    {
                      label: "Custom",
                      value:
                        draftData?.institutional_experience
                          ?.custom_option_minimum_value_procurement || "",
                    },
                  ]}
                  onGetValueOtherOptions={(value) => {
                    register("custom_option_minimum_value_procurement");
                    setValue("custom_option_minimum_value_procurement", value);
                  }}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormRadio
                  label={
                    "Sejauh mana Anda mengimplementasikan proses monitoring dari proses pengadaan yang dijalankan?"
                  }
                  controllerName={"value_implementation_procurement"}
                  values={[
                    {
                      label: 1,
                      value: 1,
                    },
                    {
                      label: 2,
                      value: 2,
                    },
                    {
                      label: 3,
                      value: 3,
                    },
                    {
                      label: 4,
                      value: 4,
                    },
                    {
                      label: 5,
                      value: 5,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  controllerName={`url_file_support_document`}
                  label={"Dokumen Pendukung *Upload Multiple"}
                  isMulti={true}
                  isDaprog={true}
                  setUploadedUrls={setUploadedUrls}
                  setControllerTarget={setControllerTarget}
                  uploadFile={mutation}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-full mt-2 flex gap-2 items-center p-4 border rounded-lg">
                <RiQuestionLine />
                Bagaimana komposisi panitia yang lembaga/institusi/perusahaan
                Anda bentuk dalam proses pelaksanaan pengadaan?
              </div>
            </div>
            <div className="border-t">
              {structureMemberFields.length > 0
                ? structureMemberFields.map((data, index) => (
                    <>
                      <div className="flex gap-4 items-end pt-2" key={data.id}>
                        <div className="w-full">
                          <div className="flex gap-4">
                            <div className="flex-1 pt-2">
                              <InputForm
                                controllerName={`structure_member.${index}.position`}
                                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                                label={"Jabatan"}
                                placeholder={"Jabatan"}
                                required={true}
                              />
                            </div>
                            <div className="flex-1 pt-2">
                              <InputForm
                                controllerName={`structure_member.${index}.total_member`}
                                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                                label={"Jumlah Orang"}
                                placeholder={"Jumlah Orang"}
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                        {index > 1 && (
                          <button
                            className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                            onClick={() => removeStructureMember(index)}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        )}
                      </div>
                    </>
                  ))
                : ""}
            </div>
            <div className="border-b" />
            <div className="flex flex-row-reverse my-5 mx-4 ">
              <Button
                className="p-4 border rounded-lg"
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Tambah Jabatan Lain
                  </div>
                }
                onClick={() => {
                  appendStructureMember({
                    position: "",
                    total_member: "",
                  });
                }}
              />
            </div>

            <div className="border-b" />
            <div className="flex">
              <div className="w-full bg-white mt-2 flex gap-2 items-center p-4 border rounded-lg">
                <RiQuestionLine />
                Apa saja pengalaman pengadaan yang pernah
                lembaga/institusi/perusahaan Anda jalankan?
                <div
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                    color: "#00BD52",
                  }}
                  onClick={() => handleExpand()}
                >
                  {!expand ? "Tutup ▲" : "Buka ▼"}
                </div>
              </div>
            </div>
            <div style={{ display: !expand && "none" }}>
              {procurementExperienceFields.length > 0
                ? procurementExperienceFields.map((data, index) => (
                    <>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            controllerName={`procurement_experience.${index}.job_name`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Nama Paket Pekerjaan"}
                            placeholder={"Nama Paket Pekerjaan"}
                            required={true}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            controllerName={`procurement_experience.${index}.location`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Lokasi"}
                            placeholder={"Lokasi"}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            controllerName={`procurement_experience.${index}.employer_name`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Nama Pemberi Tugas/Pengguna Jasa"}
                            placeholder={"Nama Pemberi Tugas/Pengguna Jasa"}
                            required={true}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            controllerName={`procurement_experience.${index}.employer_address`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Alamat Pemberi Tugas/Pengguna Jasa"}
                            placeholder={"Alamat Pemberi Tugas/Pengguna Jasa"}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <InputForm
                            controllerName={`procurement_experience.${index}.no_contract`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md`}
                            label={"Nomor Kontrak"}
                            placeholder={"Nomor Kontrak"}
                            required={true}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <AtomDatePicker
                            controllerName={`procurement_experience.${index}.date_contract`}
                            label="Tanggal Kontrak"
                            required={true}
                            newDate={true}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <InputForm
                            showPrefix={true}
                            prefix={"Rp. "}
                            controllerName={`procurement_experience.${index}.value_contract`}
                            className={`py-2 px-4 mt-2 border w-full rounded-md ${
                              errors.nama_lemtara
                                ? "border-[#F04438]"
                                : "border-green"
                            }`}
                            label={"Nilai Kontrak"}
                            placeholder={"0"}
                            required={true}
                            onChangeInput={(e) => {
                              setValue(
                                `procurement_experience.${index}.value_contract`,
                                convertMoney(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="flex-1 pt-2">
                          <AtomDatePicker
                            controllerName={`procurement_experience.${index}.end_date_contract`}
                            label="Tanggal Selesai Kontrak"
                            required={true}
                            newDate={true}
                          />
                        </div>
                        <div className="flex-1 pt-2">
                          <AtomDatePicker
                            controllerName={`procurement_experience.${index}.end_date_bast`}
                            label="Tanggal Selesai BAST"
                            required={true}
                            newDate={true}
                          />
                        </div>
                      </div>
                    </>
                  ))
                : ""}
            </div>
            <div className="border-b" />
            <div className="flex flex-row-reverse my-5 mx-4 ">
              <Button
                className="p-4 border rounded-lg"
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Tambah Data Lain
                  </div>
                }
                onClick={() => {
                  appendProcurementExperience({
                    job_name: "",
                    location: "",
                    employer_name: "",
                    employer_address: "",
                    no_contract: "",
                    date_contract: "",
                    value_contract: "",
                    end_date_contract: "",
                    end_date_bast: "",
                  });
                }}
              />
            </div>
          </>
        </CardForm>
        <CardForm label={"Lampirkan Dokumen Pendukung"} buttonCollapse={true}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  controllerName={`url_file_cooperative_contract`}
                  label={"Kontrak Kerjasama"}
                  uploadFile={mutation}
                  methods={methods}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`description_cooperative_contract`}
                  className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                  label={"Uraian Singkat Terkait Isi Dokumen"}
                  placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                  textArea={true}
                  required={true}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputFormUpload
                  controllerName={`url_file_completion_report`}
                  label={"Laporan Penyelesaian Pekerjaan"}
                  uploadFile={mutation}
                  methods={methods}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={`description_completion_report`}
                  className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                  label={"Uraian Singkat Terkait Isi Dokumen"}
                  placeholder={"Uraian Singkat Terkait Isi Dokumen"}
                  textArea={true}
                  required={true}
                />
              </div>
            </div>
          </>
        </CardForm>

        <BottomFormAction
          lastStep={true}
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </FormProvider>
    </>
  );
};

export default ProcurmentPage;
