import React, { useMemo, useEffect } from "react";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormUploadNew as InputFormUpload,
} from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import AtomSelect from "components/atoms/Select";
import "react-datepicker/dist/react-datepicker.css";
import AtomDatePicker from "components/atoms/Datepicker";
import CardForm from "components/molecules/CardForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/danaProgram/fileService";

const LandasanHukumPendirianLembaga = ({
  onSubmit = null,
  onBack = null,
  onDraftSubmit = null,
  draftData,
}) => {
  let nib_regex = /^\d{1,13}$/;
  let deed_regex = /^[a-zA-Z0-9]{1,50}$/;
  const validationSchema = useMemo(() => {
    return yup.object({
      // no_nib: yup
      //   .string()
      //   .matches(nib_regex, "NIB tidak valid")
      //   .required(`NIB wajib diisi`),
      no_deed: yup
        .string()
        .matches(deed_regex, "No. Akta tidak valid")
        .required(`No. akta wajib diisi`),
      date_deed: yup.string().required("Tanggal akta wajib diisi"),
      notary_public: yup
        .string()
        .max(255, "Nama Notaris tidak boleh lebih dari 255 karakter")
        .required("Nama Notaris wajib diisi"),
      document_nib: yup.mixed().required(),
      proof_document: yup.mixed().required(),
      additional_foundations: yup.array().of(
        yup.object().shape({
          name: yup.string(),
          desc: yup.string(),
          additional_document: yup.mixed(),
        })
      ),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { setValue } = methods;

  useEffect(() => {
    if (draftData && Object.keys(draftData).length > 0) {
      Object.keys(draftData).forEach((key) => {
        const value = draftData[key];

        if (key === "date_deed") {
          const date = value;
          setValue(key, date);
        } else if (key === "notary_public") {
          setValue(key, value);
        } else if (
          (key === "document_nib" || key === "proof_document") &&
          value
        ) {
          getFileMutation.mutate({ id: value, key: key });
        } else {
          setValue(key, value);
        }
      });
    }
    methods.trigger();
  }, [draftData, setValue, methods]);

  const {
    formState: { errors, isValid },
    control,
  } = methods;

  const {
    fields: otherDataFields,
    append: appendOtherDataFields,
    remove: removeOtherDataFields,
  } = useFieldArray({
    control,
    name: "additional_foundations",
  });
  const mutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      let temp = {
        url: response.data.data.url,
        id: response.data.data.id,
        type: response.data.data.mimeType,
        name: response.data.data.name,
        size: response.data.data.size,
      };
      setValue(file.controllerName, temp);
    } catch (error) {
      throw error;
    }
  });

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (id != null) {
        const response = await getFile(id);
        let temp = {
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: response.data.data.size,
        };
        setValue(key, temp);
      }
    } catch (error) {
      throw error;
    }
  });

  return (
    <FormProvider {...methods}>
      <>
        <CardForm label={"Landasan Hukum Pendirian Lembaga"}>
          <>
            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"no_nib"}
                  label={"NIB"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  placeholder={"xxxx xxxx xxxx"}
                />
              </div>
              <div className="flex-1"></div>
              <div className="flex-1"></div>
            </div>

            <div className="flex-1 pt-2">
              <InputFormUpload
                controllerName={"document_nib"}
                label={"Dokumen NIB"}
                required={true}
                uploadFile={mutation}
                methods={methods}
              />
            </div>

            <div className="flex gap-4">
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"no_deed"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Nomor Akta Pendirian"}
                  placeholder={"xxxx xxxx xxxx"}
                  required={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <AtomDatePicker
                  controllerName={"date_deed"}
                  label="Tanggal Akta"
                  required={true}
                  newDate={true}
                />
              </div>
              <div className="flex-1 pt-2">
                <InputForm
                  controllerName={"notary_public"}
                  label="Nama Notaris"
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  placeholder={"Nama Notaris"}
                  required={true}
                />
              </div>
            </div>

            <div className="flex-1 pt-2">
              <InputFormUpload
                controllerName={"proof_document"}
                label={"Dokumen Bukti"}
                required={true}
                uploadFile={mutation}
                methods={methods}
              />
            </div>
            {otherDataFields.length > 0 &&
              otherDataFields.map((data, index) => (
                <div
                  className="flex gap-4 items-end border-t pt-2"
                  key={data.id}
                >
                  <div className="w-full">
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`additional_foundations.${index}.name`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md`}
                          label={"Nama Data lainnya"}
                          placeholder={"Nama data lainnya"}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1 pt-2">
                        <InputForm
                          controllerName={`additional_foundations.${index}.desc`}
                          className={`px-4 mt-2 border w-full rounded-md h-32 items-start`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          textArea={true}
                        />
                      </div>
                      <div className="flex-1 pt-2">
                        <InputFormUpload
                          controllerName={`additional_foundations.${index}.additional_document`}
                          label={"Dokumen Bukti"}
                          uploadFile={mutation}
                          methods={methods}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                    onClick={() => removeOtherDataFields(index)}
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              ))}
            <div className="border"></div>
            <div className="flex flex-row-reverse my-5 mx-4 ">
              <Button
                className="p-4 border rounded-lg"
                label={
                  <div className="flex items-center gap-2 font-[14px]">
                    <RiAddLine />
                    Tambah Data Lain
                  </div>
                }
                onClick={() =>
                  appendOtherDataFields({
                    name: "",
                    desc: "",
                    additional_document: "",
                  })
                }
              />
            </div>
          </>
        </CardForm>
        <BottomFormAction
          backButtonAction={() => {
            const values = methods.getValues();
            onBack(values);
          }}
          disableDrafButton={false}
          drafButtonAction={() => {
            const values = methods.getValues();
            onDraftSubmit(values);
          }}
          disableButtonSubmit={isValid ? false : true}
          submitActionButton={() => {
            console.log(errors);
            methods.handleSubmit(onSubmit)();
          }}
        />
      </>
    </FormProvider>
  );
};

export default LandasanHukumPendirianLembaga;
