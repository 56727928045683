import React, { Fragment, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";

import { BottomFormAction, ConfirmationModal } from "components";
import AlertBlankData from "components/molecules/Alert";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { useNavigate } from "react-router";
import Checkbox from "components/atoms/Checkbox";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import { useDispatch, useSelector } from "react-redux";
import { updateFormCfP } from "app/actions";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fileUpload } from "services/danaProgram/fileService";
import {
  getDetailSupportingFile,
  postSupportingFile,
} from "services/danaProgram/callForProposalService";
import { enqueueSnackbar } from "notistack";
import FileDetail from "components/molecules/FileDetail";

const validationSchema = yup.object({
  is_file_edit: yup.boolean(),
  documents: yup
    .array()
    .of(yup.mixed())
    .when("is_file_edit", {
      is: false,
      then: (schema) => schema.min(1, "Anda harus mengisi minimal 1 Dokumen"),
    }),
  is_aggree: yup
    .bool()
    .oneOf([true], "Anda harus menyetujui syarat dan ketentuan diatas"),
});

const defaultValues = {
  documents: [],
  is_aggree: false,
  is_file_edit: false,
};

const Documents = () => {
  const navigate = useNavigate();
  const { id, editable } = useSelector(
    (state) => state?.callForProposalData?.data
  );
  const [initDocs, setInitDocs] = useState([]);
  const [showConfirmModal, setConfirmModal] = useState({
    open: false,
    type: "draft",
    cancelLabel: "Tidak",
    confirmLabel: "Iya, simpan draft",
    title: "",
    description: "",
  });
  const [payload, setPayload] = useState(null);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { getValues } = methods;

  const { data } = useQuery({
    queryKey: ["get-detail-supporting-file"],
    queryFn: async () => {
      const res = await getDetailSupportingFile(id);
      return res.data.data;
    },
  });

  useEffect(() => {
    if (data) {
      setInitDocs(data?.[0]?.documents);
    }
  }, [data]);

  useEffect(() => {
    if (initDocs?.length > 0) {
      methods.setValue("is_file_edit", true);
    } else {
      methods.setValue("is_file_edit", false);
    }
  }, [initDocs]);

  const saveDraft = () => {
    setPayload({
      call_for_proposal_id: id,
      status: "DRAFT",
      documents: getValues("documents"),
    });
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: "draft",
      cancelLabel: "Tidak",
      confirmLabel: "Iya, simpan draft",
      title: "Anda yakin akan menyimpan ini sebagai draft?",
      description:
        "Setelah anda menyimpan sebagai draft anda masih bisa melanjutkannya",
    }));
  };

  const submitForm = (values) => {
    setPayload({
      call_for_proposal_id: id,
      status: "SUBMIT",
      documents: getValues("documents"),
    });
    setConfirmModal((curr) => ({
      ...curr,
      open: true,
      type: "submit",
      cancelLabel: "Tidak, ubah data",
      confirmLabel: "Ya, kirimkan",
      title: "Anda yakin akan mengirimkan semua data ini?",
      description:
        "Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku. ",
    }));
  };

  const postDocuments = () => {
    postFile.mutate(payload);
  };

  const postFile = useMutation({
    mutationKey: ["post-file-document"],
    mutationFn: async (payload) => {
      const promise = payload?.documents?.map(async (item) => {
        const formData = new FormData();
        formData.append("file", item);
        const res = await fileUpload(formData);
        return {
          fileId: res.data.data.id,
          fileName: res.data.data.name,
          fileSize: res.data.data.size,
          mimeType: res.data.data.mimeType,
          path: res.data.data.key,
        };
      });

      return Promise.all(promise);
    },
    onSuccess: async (data) => {
      submitSupportDocs.mutate({
        call_for_proposal_id: payload.call_for_proposal_id,
        status: payload.status,
        documents: data?.length > 0 ? [...data, ...(initDocs || [])] : initDocs,
      });
    },
  });

  const submitSupportDocs = useMutation({
    mutationKey: ["submit-supporting-docs"],
    mutationFn: async (payload) => {
      const res = await postSupportingFile(payload);
      return res;
    },
    onSuccess: () => {
      setConfirmModal({ open: false });
      enqueueSnackbar("Data Berhasil Disimpan", { variant: "success" });
      navigate(`/proposal/call-for-proposal/${id}`);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <ConfirmationModal
        useGraphic
        open={showConfirmModal.open}
        onCancel={() => {
          setConfirmModal((curr) => ({
            ...curr,
            open: false,
          }));
          setPayload(null);
        }}
        cancelLabel={showConfirmModal.cancelLabel}
        confirmLabel={showConfirmModal.confirmLabel}
        onSubmit={postDocuments}
        isLoading={postFile.isLoading || submitSupportDocs.isLoading}
      >
        <div className="space-y-1">
          <div className="text-lg text-gray-900 font-semibold">
            {showConfirmModal.title}
          </div>
          <div className="text-sm text-gray-600">
            {showConfirmModal.description}
          </div>
        </div>
      </ConfirmationModal>
      <div className="space-y-6">
        <div className="bg-white rounded-xl p-10 space-y-8">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: "Call For Proposal",
                  path: `/proposal/call-for-proposal`,
                },
                {
                  label: "Pengajuan Full Proposal",
                },
              ]}
            />
          </div>
          <div className="w-full">
            <AlertBlankData
              title={`Lengkapi Data`}
              body={`Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya.`}
            />
          </div>
          <FormProvider {...methods}>
            <CardForm label="Dokumen Pendukung">
              <div className="space-y-5">
                <div className="space-y-2">
                  <div className="text-[#1D2939] font-semibold text-sm">
                    Upload Dokumen Pendukung
                    <span className="ml-1 text-error-500 text-sm">*</span>
                  </div>
                  <InputFormDropzone
                    name="documents"
                    maxFiles={5}
                    maxSize={10}
                    multiple={true}
                    informationText="PNG, JPG"
                    disabled={!editable}
                  />
                  {initDocs?.length > 0 && (
                    <>
                      {initDocs.map((item, index) => (
                        <FileDetail
                          file={item}
                          key={index}
                          hideDelete={!editable}
                          onRemove={() => {
                            const filtered = initDocs.filter(
                              (file) => file.fileId !== item.fileId
                            );
                            setInitDocs(filtered);
                          }}
                        />
                      ))}
                    </>
                  )}
                </div>
                <Checkbox
                  controllerName={`is_aggree`}
                  disabled={!editable}
                  label={() => (
                    <div>
                      <p className={"font-medium"}>Saya Setuju</p>
                      <p>
                        Dengan ini kami menyatakan bahwa Seluruh data yang kami
                        berikan pada formulir ini adalah benar adanya. Jika
                        dikemudian hari BPDLH menemukan unsur kebohongan dan
                        atau pemalsuan data, kami siap dan bersedia untuk
                        diajukan ke ranah hukum sesuai dengan undang-undang yang
                        berlaku.
                      </p>
                    </div>
                  )}
                  inputWrapperClass={
                    "bg-primary-maincolor-50 p-6 flex gap-2 items-start rounded-lg"
                  }
                />
              </div>
            </CardForm>
            <BottomFormAction
              lastStep
              backButtonAction={() => navigate(-1)}
              disableButtonSubmit={!editable}
              disableDrafButton={!editable}
              drafButtonAction={() => {
                const values = methods.getValues();
                saveDraft(values);
              }}
              // disableButtonSubmit={isValid ? false : true}
              submitActionButton={() => {
                methods.handleSubmit(submitForm)();
              }}
            />
          </FormProvider>
        </div>
      </div>
    </Fragment>
  );
};

export default Documents;
